.home-first-section {
  color: var(--white);
  user-select: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  h2 {
    margin-bottom: 15px;
    span {
      color: var(--secondary-color);
    }
  }
  .contact-wrap {
    position: absolute;
    left: 0;
    bottom: 100px;
  }
}

@media only screen and (max-width: 600px) {
  .home-first-section {
    padding-top: 50px;
  }
}
