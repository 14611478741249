.card {
  position: relative;
  z-index: 0;
  &:hover {
    cursor: crosshair;
  }
}

.card img {
  width: 100%;
  display: block;
  max-width: 150px;
  user-select: none;
  -webkit-user-drag: none;
  transition: 0.1s ease-in;
  z-index: 0;
}

.card .front {
  transform: rotateY(90deg);
  transition: all ease-in 0.2s;
  position: absolute;
  z-index: 0;
}

.flipped .front {
  transform: rotateY(0deg);
  transition-delay: 0.2s;
  z-index: 0;
}

.card .back {
  transition: all ease-in 0.2s;
  transition-delay: 0.2s;
  z-index: 0;
  &:hover {
    filter: grayscale(1);
  }
}

.flipped .back {
  transform: rotateY(90deg);
  transition-delay: 0s;
  z-index: 0;
}

@media only screen and (max-width: 600px) {
  .card img {
    max-width: 120px;
  }
}

@media only screen and (max-height: 800px) {
  .card img {
    max-width: 120px;
  }
}
