.description {
  p {
    line-height: 1.5;
    margin-bottom: 10px;
  }

  .logo {
    margin-left: 5px;
    display: inline-flex;
    transition: 0.2s ease-in;
    &:hover {
      transform: scale(1.1);
    }
    &--google {
      svg {
        width: 70px;
        transform: translateY(7px);
      }
    }
  }
  .projects-container {
    margin: 50px 0 40px 0;
    .projects {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 8px;
      margin-left: 60px;
      .project {
        width: 34%;
        margin: auto;
        text-align: left;
        list-style-type: none;
        position: relative;
        font-size: 14px;
        &::before {
          content: url(../icons/triangle.svg);
          position: absolute;
          left: -25px;
          bottom: -2px;
          height: auto;
          width: 14px;
          transition: 0.2s ease-in;
        }
        &:hover {
          a {
            color: var(--white);
          }
          &::before {
            left: -20px;
            transform: scale(1.3);
            bottom: -2px;
          }
        }
        a {
          text-decoration: none;
          color: var(--secondary-color);
          transition: 0.2s ease-in;
        }
      }
    }
  }

  .play-btn {
    background: var(--base-color);
    color: var(--white);
    border-radius: 2px;
    border: 1px solid var(--secondary-color);
    padding: 3px 12px;
    font-size: 12px;
    transition: 0.2s ease-in;
    margin-top: 40px;
    &:hover {
      background: var(--secondary-color);
      color: var(--base-color);
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 910px) {
  .description {
    .projects-container {
      .projects {
        .project {
          width: 50%;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .description {
    .projects-container {
      margin-top: 40px;
      .projects {
        grid-gap: 10px 20px;
        transform: unset;
        margin-left: 0;
        .project {
          width: 100%;
          display: flex;
          justify-content: center;
          &:before {
            content: "";
          }
        }
      }
    }
  }
}
