@import url(https://fonts.googleapis.com/css2?family=Sora:wght@300&display=swap);
*,*::after,*::before{margin:0;padding:0;box-sizing:border-box;font-family:"Sora", sans-serif;font-weight:300}:root{--base-color: #133b5c;--base-color-dark: #1d2d50;--secondary-color: #fcda31;--black: #212121;--white: #fafafa;--header-size: 50px;--content-size: 1000px}body{transition:all 0.5s linear 0s}html{scrollbar-color:var(---base-color) var(--secondary-color);scrollbar-width:thin}::-webkit-scrollbar{width:8px;height:8px;background-color:var(---base-color)}::-webkit-scrollbar-thumb{background:var(--secondary-color);border-radius:4px}

.section{height:calc(100vh - var(--header-size) - 40px)}.content{width:100%;max-width:var(--content-size);margin:0 auto;padding:0 20px}.tooltip{max-width:320px;line-height:1.5}@media only screen and (max-width: 600px){.section{height:100%}}

.header{position:fixed;top:0;left:0;width:100%;background:var(--white);height:var(--header-size);color:var(--base-color);box-shadow:0px 6px 10px 3px rgba(0,0,0,0.31);z-index:1}.header-content{height:100%;display:flex;align-items:center;justify-content:space-between;-webkit-user-select:none;user-select:none}.header-content .logo{display:flex}.header-content .logo:hover{cursor:grab}.header-content .logo .logo-image{width:25px;height:100%;margin-right:5px;transition:0.2s ease-in}.header-content .logo .logo-image:last-of-type{margin-right:0}.header-content .logo .logo-image__square{transform:rotate(180deg)}.header-content .logo:hover .logo-image{margin-right:8px}.header-content .logo:hover .logo-image__triangle{transform:rotate(270deg)}.header-content .logo:hover .logo-image__circle{transform:rotate(360deg)}.header-content .logo:hover .logo-image__square{transform:rotate(180deg) scale(1.1)}.header-content .job-title{font-size:18px}@media only screen and (max-width: 600px){.header .header-content .job-title{font-size:16px}}

footer{height:40px;background:var(--black);display:flex;justify-content:center;align-items:center;color:var(--white);font-size:12px;-webkit-user-select:none;user-select:none}footer span{color:var(--secondary-color);margin-left:3px}

.section__home{margin-top:var(--header-size);background:#100f2b;background:linear-gradient(18deg, #100f2b 35%, #133b5c 95%)}.section__home .home-content{display:flex;flex-direction:column;justify-content:space-between;height:100%}.section__home .home-content section{position:relative;height:100%;width:100%}.section__home .square{width:40px;position:absolute;top:var(--header-size);left:6%;transition:5s ease-in;opacity:0}.section__home .square__visible{top:35%;opacity:0.8;z-index:0;transform:rotate(215deg)}.section__home .square__animating{animation:floating 15s linear infinite}.section__home .circle{position:absolute;width:40px;bottom:100px;right:50px;opacity:0;transition:0.3s ease-in}.section__home .circle__animating{opacity:1;animation:spin 4s infinite linear}.section__home .triangle{width:35px;position:absolute;top:var(--header-size);right:6%;transition:5s ease-in;opacity:0}.section__home .triangle__visible{top:35%;opacity:0.8;z-index:0;transform:rotate(215deg)}.section__home .triangle__animating{animation:floating-triangle 15s linear infinite}@keyframes floating{0%{top:35%;opacity:0.8;transform:rotate(215deg)}50%{top:55%;opacity:0.4;transform:rotate(80deg)}100%{top:35%;opacity:0.8;transform:rotate(215deg)}}@keyframes floating-triangle{0%{opacity:0.8;transform:rotate(215deg)}40%{right:35%;opacity:0.4;transform:rotate(80deg)}100%{right:6%;opacity:0.8;transform:rotate(215deg)}}@keyframes spin{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}@media only screen and (max-width: 600px){.section__home .home-content section{height:unset}.section__home .circle{width:30px;bottom:20px;right:20px}}

.home-first-section{color:var(--white);-webkit-user-select:none;user-select:none;display:flex;flex-direction:column;justify-content:flex-end;align-items:center;text-align:center}.home-first-section h2{margin-bottom:15px}.home-first-section h2 span{color:var(--secondary-color)}.home-first-section .contact-wrap{position:absolute;left:0;bottom:100px}@media only screen and (max-width: 600px){.home-first-section{padding-top:50px}}

.second-section{min-height:60vh;display:flex;align-items:flex-start;justify-content:center;color:var(--white);text-align:center;padding-top:80px}@media only screen and (max-width: 600px){.second-section{padding-top:40px;min-height:unset}}@media only screen and (max-height: 800px){.second-section{padding-top:20px}}

.description p{line-height:1.5;margin-bottom:10px}.description .logo{margin-left:5px;display:inline-flex;transition:0.2s ease-in}.description .logo:hover{transform:scale(1.1)}.description .logo--google svg{width:70px;transform:translateY(7px)}.description .projects-container{margin:50px 0 40px 0}.description .projects-container .projects{display:grid;grid-template-columns:1fr 1fr;grid-gap:8px;margin-left:60px}.description .projects-container .projects .project{width:34%;margin:auto;text-align:left;list-style-type:none;position:relative;font-size:14px}.description .projects-container .projects .project::before{content:url(/static/media/triangle.c66faac4.svg);position:absolute;left:-25px;bottom:-2px;height:auto;width:14px;transition:0.2s ease-in}.description .projects-container .projects .project:hover a{color:var(--white)}.description .projects-container .projects .project:hover::before{left:-20px;transform:scale(1.3);bottom:-2px}.description .projects-container .projects .project a{text-decoration:none;color:var(--secondary-color);transition:0.2s ease-in}.description .play-btn{background:var(--base-color);color:var(--white);border-radius:2px;border:1px solid var(--secondary-color);padding:3px 12px;font-size:12px;transition:0.2s ease-in;margin-top:40px}.description .play-btn:hover{background:var(--secondary-color);color:var(--base-color);cursor:pointer}@media only screen and (max-width: 910px){.description .projects-container .projects .project{width:50%}}@media only screen and (max-width: 600px){.description .projects-container{margin-top:40px}.description .projects-container .projects{grid-gap:10px 20px;transform:unset;margin-left:0}.description .projects-container .projects .project{width:100%;display:flex;justify-content:center}.description .projects-container .projects .project:before{content:""}}

.memory-game{position:relative}.memory-game .card-grid{margin-top:40px;display:grid;grid-template-columns:repeat(4, 1fr);grid-gap:20px}.memory-game .message{position:absolute;bottom:-50px;width:100%;display:flex;justify-content:center}.memory-game .time-left{min-height:250px;display:flex;justify-content:center;align-items:center;flex-direction:column;-webkit-user-select:none;user-select:none}.memory-game .time-left .know{line-height:1.8}.memory-game .time-left .know span{color:var(--secondary-color)}.memory-game .time-left .countdown{color:var(--secondary-color);font-size:40px;margin-top:20px}@media only screen and (max-width: 600px){.memory-game .card-grid{grid-template-columns:repeat(2, 1fr);grid-gap:10px}.memory-game .card-grid .card{display:flex;justify-content:center}.memory-game .message{font-size:14px}}

.card{position:relative;z-index:0}.card:hover{cursor:crosshair}.card img{width:100%;display:block;max-width:150px;-webkit-user-select:none;user-select:none;-webkit-user-drag:none;transition:0.1s ease-in;z-index:0}.card .front{transform:rotateY(90deg);transition:all ease-in 0.2s;position:absolute;z-index:0}.flipped .front{transform:rotateY(0deg);transition-delay:0.2s;z-index:0}.card .back{transition:all ease-in 0.2s;transition-delay:0.2s;z-index:0}.card .back:hover{filter:grayscale(1)}.flipped .back{transform:rotateY(90deg);transition-delay:0s;z-index:0}@media only screen and (max-width: 600px){.card img{max-width:120px}}@media only screen and (max-height: 800px){.card img{max-width:120px}}

.contact{max-height:10vh;display:flex;align-items:center;justify-content:center;flex-direction:column}.contact .linkedin{margin-right:40px}.contact .social-icon{fill:var(--white);transition:0.2s ease-in;width:20px}.contact .social-icon:hover{fill:var(--secondary-color)}.contact .mail{margin-bottom:20px;text-decoration:none;color:var(--secondary-color);font-size:12px;transition:0.2s ease-in}.contact .mail span{color:var(--white)}.contact .mail:hover{color:var(--white)}@media only screen and (max-width: 600px){.contact{margin:70px 0 20px 0}}

