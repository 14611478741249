.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: var(--white);
  height: var(--header-size);
  color: var(--base-color);
  box-shadow: 0px 6px 10px 3px rgba(0, 0, 0, 0.31);
  z-index: 1;
  &-content {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;
    .logo {
      display: flex;
      &:hover {
        cursor: grab;
      }
      .logo-image {
        width: 25px;
        height: 100%;
        margin-right: 5px;
        transition: 0.2s ease-in;
        &:last-of-type {
          margin-right: 0;
        }
        &__square {
          transform: rotate(180deg);
        }
      }
      &:hover {
        .logo-image {
          margin-right: 8px;
          &__triangle {
            transform: rotate(270deg);
          }
          &__circle {
            transform: rotate(360deg);
          }
          &__square {
            transform: rotate(180deg) scale(1.1);
          }
        }
      }
    }
    .job-title {
      font-size: 18px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .header {
    .header-content {
      .job-title {
        font-size: 16px;
      }
    }
  }
}
