.second-section {
  min-height: 60vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  color: var(--white);
  text-align: center;
  padding-top: 80px;
}

@media only screen and (max-width: 600px) {
  .second-section {
    padding-top: 40px;
    min-height: unset;
  }
}

@media only screen and (max-height: 800px) {
  .second-section {
    padding-top: 20px;
  }
}
