.contact {
  max-height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .linkedin {
    margin-right: 40px;
  }
  .social-icon {
    fill: var(--white);
    transition: 0.2s ease-in;
    width: 20px;
    &:hover {
      fill: var(--secondary-color);
    }
  }
  .mail {
    margin-bottom: 20px;
    text-decoration: none;
    color: var(--secondary-color);
    font-size: 12px;
    transition: 0.2s ease-in;
    span {
      color: var(--white);
    }
    &:hover {
      color: var(--white);
    }
  }
}

@media only screen and (max-width: 600px) {
  .contact {
    margin: 70px 0 20px 0;
  }
}
