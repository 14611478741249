.section {
  &__home {
    margin-top: var(--header-size);
    background: rgb(16, 15, 43);
    background: linear-gradient(
      18deg,
      rgba(16, 15, 43, 1) 35%,
      rgba(19, 59, 92, 1) 95%
    );
    .home-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      section {
        position: relative;
        height: 100%;
        width: 100%;
      }
    }
    .square {
      width: 40px;
      position: absolute;
      top: var(--header-size);
      left: 6%;
      transition: 5s ease-in;
      opacity: 0;
      &__visible {
        top: 35%;
        opacity: 0.8;
        z-index: 0;
        transform: rotate(215deg);
      }
      &__animating {
        animation: floating 15s linear infinite;
      }
    }
    .circle {
      position: absolute;
      width: 40px;
      bottom: 100px;
      right: 50px;
      opacity: 0;
      transition: 0.3s ease-in;
      &__animating {
        opacity: 1;
        animation: spin 4s infinite linear;
      }
    }
    .triangle {
      width: 35px;
      position: absolute;
      top: var(--header-size);
      right: 6%;
      transition: 5s ease-in;
      opacity: 0;
      &__visible {
        top: 35%;
        opacity: 0.8;
        z-index: 0;
        transform: rotate(215deg);
      }
      &__animating {
        animation: floating-triangle 15s linear infinite;
      }
    }
  }
}

@keyframes floating {
  0% {
    top: 35%;
    opacity: 0.8;
    transform: rotate(215deg);
  }
  50% {
    top: 55%;
    opacity: 0.4;
    transform: rotate(80deg);
  }
  100% {
    top: 35%;
    opacity: 0.8;
    transform: rotate(215deg);
  }
}
@keyframes floating-triangle {
  0% {
    opacity: 0.8;
    transform: rotate(215deg);
  }
  40% {
    right: 35%;
    opacity: 0.4;
    transform: rotate(80deg);
  }
  100% {
    right: 6%;
    opacity: 0.8;
    transform: rotate(215deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  .section {
    &__home {
      .home-content {
        section {
          height: unset;
        }
      }
      .circle {
        width: 30px;
        bottom: 20px;
        right: 20px;
      }
    }
  }
}
