footer {
  height: 40px;
  background: var(--black);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  font-size: 12px;
  user-select: none;
  span {
    color: var(--secondary-color);
    margin-left: 3px;
  }
}
