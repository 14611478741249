.memory-game {
  position: relative;
  .card-grid {
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
  }
  .message {
    position: absolute;
    bottom: -50px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .time-left {
    min-height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    user-select: none;
    .know {
      line-height: 1.8;
      span {
        color: var(--secondary-color);
      }
    }
    .countdown {
      color: var(--secondary-color);
      font-size: 40px;
      margin-top: 20px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .memory-game {
    .card-grid {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px;
      .card {
        display: flex;
        justify-content: center;
      }
    }
    .message {
      font-size: 14px;
    }
  }
}
