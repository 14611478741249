.section {
  height: calc(100vh - var(--header-size) - 40px);
}

.content {
  width: 100%;
  max-width: var(--content-size);
  margin: 0 auto;
  padding: 0 20px;
}

.tooltip {
  max-width: 320px;
  line-height: 1.5;
}

@media only screen and (max-width: 600px) {
  .section {
    height: 100%;
  }
}
