@import url("https://fonts.googleapis.com/css2?family=Sora:wght@300&display=swap");

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Sora", sans-serif;
  font-weight: 300;
}

:root {
  --base-color: #133b5c;
  --base-color-dark: #1d2d50;
  --secondary-color: #fcda31;

  --black: #212121;
  --white: #fafafa;

  --header-size: 50px;
  --content-size: 1000px;
}

body {
  transition: all 0.5s linear 0s;
}

/* Firefox */
html {
  scrollbar-color: var(---base-color) var(--secondary-color);
  scrollbar-width: thin;
}

/* WebKit and Chromiums */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: var(---base-color);
}

::-webkit-scrollbar-thumb {
  background: var(--secondary-color);
  border-radius: 4px;
}
